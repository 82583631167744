#play{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.playSvg{
    width: 60px;
    height: auto;
    position: relative;
    top: 51px;
}

.playSvg path{
    fill:white;
}

.pauseSvg{
    width: 44px;
    height: auto;
    top: 39px;
    position: relative;
}

.pauseSvg path{
    fill:white;
}