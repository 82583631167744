.social-icons img {
	padding-right: 20px;
}

.social-icons a .bc {
	width: 64px;
}

.social-icons a .ig {
	width: 20px;
}

.social-icons a .soundcloud {
	width: 45px;
}

.social-icons a .twitter {
	width: 25px;
}

.footer {
	margin-bottom: 20px;
}
