@keyframes hoverIn {
    0% {
        opacity: .1;
        top: 10px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

#c{
    width:100%;
    height:100%;
}

.track a{
    text-decoration: none;
}

.track:hover{
    color: white;
    /* padding:10px; */
    /* font-family:cursive;
    font-size: 1rem; */
    border-radius: 0;
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    animation: hoverIn 1s ease-in-out;
    position: relative;
    z-index: 99;
}

