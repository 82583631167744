body {
	padding: 0;
	margin: 0;
	background: black;
	color: white;
	height: 100%;
	width: 100%;
	position: fixed;
	font-size: 14px;
}

#root {
	height: 100%;
}

a {
	color: white;
}

.App {
	font-family: sans-serif;
	text-align: center;
	height: 100%;
	position: relative;
	font-family: "Inter", sans-serif;
}

.nav-items a {
	padding: 20px 20px 20px 0;
}

.logo {
	padding: 10px 0 20px 0 !important;
}

.grid li {
	font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {
	.nav-items {
		justify-content: center !important;
		font-size: 10px;
	}

	.nav-items a {
		padding: 20px 10px 20px 0;
	}

	.logo {
		padding: 10px 0 20px 0 !important;
	}
}
