canvas {
  width: 775px;
  height: 605px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


